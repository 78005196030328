<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="值班名称">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.dutyName" />
                </a-form-item>
                <a-form-item label="值班地点">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.dutyAdr" />
                </a-form-item>
                <a-form-item label="值班时间">
                    <a-range-picker class="search-range-date" v-model="searchInfo.endDate" />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button class="m-r-8" @click="reset"><a-icon type="reload" />重置</a-button>
                    
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <router-link class="item"  to="/pcms/labour/onDuty/add">
                    <a-button type="primary">添加值班</a-button>
                </router-link>
                
            </a-row>
        </section>

        <a-row type="flex" :gutter="20" class="list-card-container" v-if="listMap.length">
            <a-col :span="6" v-for="(item,key) in listMap" :key="key" class="m-t-16 list-item-wrap">
                <a-row class="list-card-bg">
                    <a-row class="list-wrap">
                        <a-col>
                            <a-row class="list-item list-title" :title="item.dutyName">
                                {{item.dutyName}}
                            </a-row>
                            
                            <a-row class="list-item">
                                <span class="key">值班地点：</span>
                                <span class="val">{{item.dutyAdr}}</span>
                            </a-row>
                            <a-row class="list-item">
                                <span class="key">值班时间：</span>
                                <span class="val">
                                    <span class="m-r-8">{{item.startDate}}</span>
                                    <span class="m-r-8">{{item.startDateType == 1 ? '上午' : '下午'}}</span>
                                    <span class="m-r-8">~</span>
                                    <span class="m-r-8">{{item.endDate}}</span>
                                    <span>{{item.endDateType == 1 ? '上午' : '下午'}}</span>
                                </span>
                            </a-row>
                            <a-row class="list-item">
                                <span class="key">值班人员：</span>
                                <span class="val">{{item.dutyPersons}}</span>
                                
                            </a-row>
                            <a-row class="list-item" style="padding-bottom:30px">
                                <span class="key">值班人数：</span>
                                <span class="val">{{item.personNum || 0}}</span>
                            </a-row>
                            <a-row class="list-btn-group-position" style="right:15px">
                                <router-link :to="{path:'/pcms/labour/onDuty/details',query:{id:item.id}}"><a-button size="small">查看</a-button></router-link>
                                <a-dropdown v-if="item.dutyState === 0">
                                    <a-button size="small">更多<a-icon type="caret-down" /></a-button>
                                    <a-menu slot="overlay" >
                                        <a-menu-item key="1">
                                            <router-link :to="{path:'/pcms/labour/onDuty/add',query:{id:item.id}}">编辑</router-link>    
                                        </a-menu-item>
                                        <a-menu-item key="2">
                                            <a-popconfirm title="确定删除？" @confirm="deleteDutyApply(item.id)">删除</a-popconfirm>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                                <a-popconfirm v-else title="确定删除？" @confirm="deleteDutyApply(item.id)"><a-button size="small">删除</a-button></a-popconfirm>
                            </a-row>
                        </a-col>
                    </a-row>
                    <a-row class="list-status">{{item.dutyState === 1 ? '进行中' : item.dutyState === 2 ? '已结束' : '未开始'}}</a-row>
                </a-row>
            </a-col>
        </a-row>
        <empty v-else :height="430" />
        <!-- 分页 -->
        <section class="pagination-container">
            <a-pagination 
            size="default" 
            :pageSize="pageSize" 
            :current="pageNum" 
            :total="total" 
            hide-on-single-page 
            :show-total="(total) => `共${total}条`"
            :showQuickJumper="true"
		    :showSizeChanger="false"
            @change="changePageNum" />
        </section>
    </section>
    
</template>

<script>
import empty from '@/components/empty'
export default {
    data(){
        return{
            total:0,
            pageSize:8,
            pageNum:1,
            listMap:[],
            searchInfo:{
                endDate:[]
            }
        }
    },
    components:{empty},
    created() {
       
    },
    mounted(){
        this.queryDutyApplyList()
    },
    methods:{
        
         queryDutyApplyList(){
             let params = {...this.searchInfo}
             params.startDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : ''
             params.endDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
             params.current = this.pageNum
             params.size = this.pageSize
            this.$api.queryDutyApplyList(params).then(res =>{
                if(res.code === 200){
                    this.listMap = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                }
            })
        },
        reset(){
            this.searchInfo = {
                endDate:[]
            }
            this.pageNum = 1
            this.queryDutyApplyList()
        },
        search(){
            this.pageNum = 1
            this.queryDutyApplyList()
        },
        //分页
        changePageNum(p){
            this.pageNum = p
            this.queryDutyApplyList()
        },
        //删除
        deleteDutyApply(id){
            this.$api.deleteDutyApply(id).then(res =>{
                if(res.code === 200){
                    this.queryDutyApplyList()
                }
            })
        }
    }
}
</script>